import React, { Component } from 'react'
import './member.css'
import { get, post } from '../../ajax/tools'
import PopUp from '../round/PopUp'
import { API_URL } from '../../ajax/config'
import ThreeLevelLinkage from '../round/ThreeLevelLinkage'
import Triangle from '../round/Triangle'

class Prize extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Ren: 12,
      postProvince: '',
      postCity: '',
      postArea: '',
      postAddress: '',
      postPhone: '',
      orderId: '',
      PrizeTableList: '',
      User: {},
    }
  }
  componentDidMount() {
    get({ url: 'user/basic_info' }).then((res) => {
      if (res.errno === '200') {
        let User = res.data[0]
        this.setState({
          User: User,
        })
        let zigeUrl = ''
        if (User.type === 1) {
          zigeUrl = '/user/match_award_zige_org_2024'
        } else if (User.type === 0) {
          zigeUrl = '/user/match_award_zige_2024'
        }
        get({ url: zigeUrl }).then((res) => {
          if (res.status === 1 && res.order_id) {
            if (this.state.User.type === 1) {
              this.setState({
                Ren: 5,
              })
            } else if (this.state.User.type === 0) {
              post({
                url: '/user/show_award_details_2024',
              }).then((res) => {
                if (res.errno === '200') {
                  this.setState({
                    Ren: 4,
                    PrizeTableList: res.data,
                  })
                } else {
                  this.setState({
                    PopUp: '提示弹窗',
                    MessageTip: res.errmsg + '！',
                  })
                }
              })
            }
          } else if (res.status === 0 && res.order_id) {
            // 有资格未付款
            this.setState({
              orderId: res.order_id,
              Ren: 2,
            })
            this.PayWeChat = setInterval(() => {
              get({ url: '/user/get_order_state/' + res.order_id }).then(
                (res) => {
                  if (res.state === 1) {
                    clearInterval(this.PayWeChat)
                    alert('支付成功！')
                    this.setState({
                      postProvince: '',
                      postCity: '',
                      postArea: '',
                      postAddress: '',
                      postPhone: '',
                      Ren: 3,
                    })
                  }
                }
              )
            }, 2000)
          } else if (res.errno === '200') {
            // 有资格
            this.setState({
              postProvince: '',
              postCity: '',
              postArea: '',
              postAddress: '',
              postPhone: '',
              Ren: 1,
            })
          } else {
            this.setState({
              Ren: null,
            })
          }
        })
      } else {
        const { history } = this.props
        history.push('/login')
      }
    })
  }
  //三级联动的逻辑处理
  ChildLinkClick = (event) => {
    if (event.Type === '地址') {
      if (event.Title === '省') {
        this.setState({
          postProvince: event.ValueP,
          postCity: event.ValueC,
          postArea: event.ValueA,
        })
      } else if (event.Title === '市') {
        this.setState({
          postProvince: event.ValueP,
          postCity: event.ValueC,
          postArea: event.ValueA,
        })
      } else if (event.Title === '区') {
        this.setState({
          postProvince: event.ValueP,
          postCity: event.ValueC,
          postArea: event.ValueA,
        })
      }
    }
  }
  //详细地址
  AddressChange(event) {
    this.setState({
      postAddress: event.target.value,
    })
  }
  //电话号码
  postPhoneChange(event) {
    this.setState({
      postPhone: event.target.value,
    })
  }
  submitPostMessage() {
    if (
      !this.state.postProvince ||
      !this.state.postCity ||
      !this.state.postArea ||
      !this.state.postAddress ||
      !this.state.postPhone
    ) {
      this.setState({
        PopUp: '提示弹窗',
        MessageTip: '请确认邮寄信息是否完整！',
      })
    } else {
      let postUrl = ''
      if (this.state.User.type === 1) {
        postUrl = 'user/match_award_order_org_2024'
      } else if (this.state.User.type === 0) {
        postUrl = 'user/match_award_order_2024'
      }
      post({
        url: postUrl,
        data: {
          address:
            this.state.postProvince +
            this.state.postCity +
            this.state.postArea +
            this.state.postAddress,
          mobile: this.state.postPhone,
          province: this.state.postProvince,
          city: this.state.postCity,
          county: this.state.postArea,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.setState({
            orderId: res.order_id,
            Ren: 2,
          })
          this.PayWeChat = setInterval(() => {
            get({ url: '/user/get_order_state/' + res.order_id }).then(
              (res) => {
                if (res.state === 1) {
                  clearInterval(this.PayWeChat)
                  alert('支付成功！')
                  this.setState({
                    postProvince: '',
                    postCity: '',
                    postArea: '',
                    postAddress: '',
                    postPhone: '',
                    Ren: 3,
                  })
                }
              }
            )
          }, 2000)
        } else {
          this.setState({
            PopUp: '提示弹窗',
            MessageTip: res.errmsg + '！',
          })
        }
      })
    }
  }
  ChildBackTip = (event) => {
    if (event === 'CrossOut') {
      this.setState({
        PopUp: '',
      })
    }
  }
  Back1() {
    clearInterval(this.PayWeChat)
    post({
      url: '/user/del_goods_order',
      data: {
        order_id: this.state.orderId,
      },
    }).then((res) => {
      let zigeUrl = ''
      if (this.state.User.type === 1) {
        zigeUrl = '/user/match_award_zige_org_2024'
      } else if (this.state.User.type === 0) {
        zigeUrl = '/user/match_award_zige_2024'
      }
      get({ url: zigeUrl }).then((res) => {
        if (res.status === 1 && res.order_id) {
          // 有资格并付款
          this.setState({
            Ren: null,
          })
        } else if (res.status === 0 && res.order_id) {
          // 有资格未付款
          this.setState({
            orderId: res.order_id,
            Ren: 2,
          })
          this.PayWeChat = setInterval(() => {
            get({ url: '/user/get_order_state/' + res.order_id }).then(
              (res) => {
                if (res.state === 1) {
                  clearInterval(this.PayWeChat)
                  alert('支付成功！')
                  this.setState({
                    postProvince: '',
                    postCity: '',
                    postArea: '',
                    postAddress: '',
                    postPhone: '',
                    Ren: 3,
                  })
                }
              }
            )
          }, 2000)
        } else if (res.errno === '200') {
          // 有资格
          this.setState({
            postProvince: '',
            postCity: '',
            postArea: '',
            postAddress: '',
            postPhone: '',
            Ren: 1,
          })
        } else {
          this.setState({
            Ren: null,
          })
        }
      })
    })
  }
  About() {
    window.location.href = '/aboutMyself'
  }
  message() {
    window.location.href = '/message'
  }
  componentWillUnmount = () => {
    clearInterval(this.PayWeChat)
    this.setState = () => {}
  }
  Back2() {
    let zigeUrl = ''
    if (this.state.User.type === 1) {
      zigeUrl = '/user/match_award_zige_org_2024'
    } else if (this.state.User.type === 0) {
      zigeUrl = '/user/match_award_zige_2024'
    }
    get({ url: zigeUrl }).then((res) => {
      if (res.status === 1 && res.order_id) {
        // 已经领取过奖项
        if (this.state.User.type === 1) {
          this.setState({
            Ren: 5,
          })
        } else if (this.state.User.type === 0) {
          post({
            url: '/user/show_award_details_2024',
          }).then((res) => {
            if (res.errno === '200') {
              this.setState({
                Ren: 4,
                PrizeTableList: res.data,
              })
            } else {
              this.setState({
                PopUp: '提示弹窗',
                MessageTip: res.errmsg + '！',
              })
            }
          })
        }
      } else if (res.status === 0 && res.order_id) {
        // 有资格未付款
        this.setState({
          orderId: res.order_id,
          Ren: 2,
        })
        this.PayWeChat = setInterval(() => {
          get({ url: '/user/get_order_state/' + res.order_id }).then((res) => {
            if (res.state === 1) {
              clearInterval(this.PayWeChat)
              alert('支付成功！')
              this.setState({
                postProvince: '',
                postCity: '',
                postArea: '',
                postAddress: '',
                postPhone: '',
                Ren: 3,
              })
            }
          })
        }, 2000)
      } else if (res.errno === '200') {
        // 有资格
        this.setState({
          postProvince: '',
          postCity: '',
          postArea: '',
          postAddress: '',
          postPhone: '',
          Ren: 1,
        })
      } else {
        this.setState({
          Ren: null,
        })
      }
    })
  }
  matchNameReg(str) {
    if (str.includes('第一阶段')) {
      return '第一阶段'
    } else if (str.includes('第二阶段')) {
      return '第二阶段'
    } else if (str.includes('第三阶段')) {
      return '第三阶段'
    }
  }
  render() {
    return (
      <div
        className={'clearfix scrollBarTwo'}
        style={{
          position: 'relative',
          width: 896,
          height:
            window.innerHeight - 180 > 600 ? window.innerHeight - 180 : 600,
          minHeight: 40,
          margin: '0 auto',
        }}
      >
        {this.state.Ren === null && (
          <div style={{ fontSize: '36px', color: 'red', margin: '200px' }}>
            暂无相关赛事领奖资格
          </div>
        )}
        {this.state.Ren === 1 && (
          <div>
            <div className={'PrizeTitle'}>领奖信息填写</div>
            <div className={'PrizeMessage'}>
              <div className={'PrizeItem'}>
                <div>领奖地址：</div>
                <div
                  className={'ReInfoInputOddBox'}
                  style={{ float: 'none', display: 'inline-block' }}
                >
                  <ThreeLevelLinkage
                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                    type={'地址'}
                    Width={418}
                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                  />
                </div>
              </div>
              <div>
                <input
                  className={'ReInfoInputOdd'}
                  type={'text'}
                  name={'Address'}
                  value={this.state.AdPost}
                  onChange={this.AddressChange.bind(this)}
                  placeholder={'请输入详细邮寄地址'}
                  autoComplete={'off'}
                  style={{
                    marginTop: '10px',
                    marginLeft: '100px',
                    float: 'left',
                    width: '400px',
                  }}
                />
              </div>
            </div>
            <div className={'PrizeMessage'}>
              <div className={'PrizeItem'}>
                电话号码：{' '}
                <input
                  className={'ReInfoInputOdd'}
                  type={'text'}
                  name={'Address'}
                  value={this.state.postPhone}
                  onChange={this.postPhoneChange.bind(this)}
                  placeholder={'请输入电话号码'}
                  autoComplete={'off'}
                  style={{
                    marginTop: '10px',
                    float: 'left',
                    width: '400px',
                  }}
                />
              </div>
            </div>
            <div className={'PrizeSubmitArea'}>
              <div
                className={'PrizeButton'}
                onClick={() => this.submitPostMessage()}
              >
                提交
              </div>
              <div className={'PrizeTips'}>
                *提交后需微信扫码支付邮寄费用<br></br>
                邮寄费用根据邮寄省份距离略有不同，费用范围为：10元~25元<br></br>
                (暂不支持港澳台地区及其他国家邮寄，敬请谅解)
              </div>
            </div>
          </div>
        )}
        {this.state.Ren === 2 && (
          <div>
            <div
              className={'VipMainTitBox clearfix'}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className={'SubjectDownIco'}
                style={{
                  float: 'left',
                  width: 38,
                  height: 48,
                  position: 'relative',
                }}
                onClick={this.Back1.bind(this)}
              >
                <Triangle
                  Direction={'left'}
                  Color={'#0488CA'}
                  Width={'10px'}
                  Height={'10px'}
                  Top={'14px'}
                  Right={'8px'}
                />
              </div>
              <div className={'VipBackWord'} onClick={this.Back1.bind(this)}>
                返回{' '}
              </div>
              <span
                style={{
                  fontSize: '20px',
                  color: '#bb0000',
                  marginLeft: '15px',
                }}
              >
                *返回后可取消并修改邮寄信息
              </span>
            </div>
            <div
              className={'TrickTitle'}
              style={{ marginTop: 40, color: '#000' }}
            >
              支付订单
            </div>
            <div>
              <div className={'MoneyWord'}>
                <i className={'weixinIco'}> </i>使用微信扫码支付
              </div>
              <div className={'MoneyPhoto'} style={{ width: 192, height: 192 }}>
                <img
                  className={'PayCodePhoto'}
                  alt={''}
                  src={
                    API_URL + '/payment/WXpay_goods_order/' + this.state.orderId
                  }
                />
              </div>
              <div className={'AltWord'}>*请稍等片刻生成二维码</div>
              <div
                className={'AddressShow'}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                如有问题请&nbsp;&nbsp;
                <span
                  style={{ color: '#ff0000', fontSize: 16, cursor: 'pointer' }}
                  onClick={this.About.bind(this)}
                >
                  联系我们
                </span>
                &nbsp;&nbsp;我们会及时回复！
              </div>
            </div>
          </div>
        )}
        {this.state.Ren === 3 && (
          <div>
            <div
              className={'ReSuccessBox DisplayFlex'}
              style={{ marginTop: 180, marginBottom: 24 }}
            >
              <div className={'ReSuccessIco'}> </div>
              <div
                className={'ReSuccessWord'}
                style={{ float: 'left', marginLeft: 20 }}
              >
                支付成功！
              </div>
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{
                marginTop: 20,
                color: '#ff0000',
                fontSize: 15,
                textDecoration: 'none',
              }}
            >
              *您已操作成功，请耐心等待并关注{' '}
              <span
                style={{
                  textDecoration: 'underline',
                  color: '#1B7CFF',
                  cursor: 'pointer',
                }}
                onClick={this.message.bind(this)}
              >
                系统消息
              </span>
              ，我们会尽快联系您。
            </div>

            <div style={{ width: 520, margin: '40px auto' }}>
              <div className={'PromoCodeStepTit'}>如需开具发票请联系：</div>
              <div
                className={'PromoCodeStepCon'}
                style={{ fontSize: 20, color: '#ff0000', textIndent: 'unset' }}
              >
                米老师 13691581580
              </div>
            </div>
            <div
              className={'ManualReviewBlackWord'}
              style={{ marginTop: 60, color: '#F5495D' }}
              onClick={this.Back2.bind(this)}
            >
              返回
            </div>
          </div>
        )}
        {this.state.Ren === 4 && (
          <div style={{ fontSize: '25px', margin: '15px', fontWeight: '600' }}>
            获奖信息
            <div className={'PrizeTableInfoBox'}>
              <div className={'PrizeTableHeader'}>
                <div className={'PrizeTableHeaderInfo'} style={{ width: 150 }}>
                  赛事阶段
                </div>
                <div className={'PrizeTableHeaderInfo'} style={{ width: 400 }}>
                  赛事项目
                </div>
                <div className={'PrizeTableHeaderInfo'} style={{ width: 150 }}>
                  项目结果
                </div>
              </div>
              <div className={'PrizeTableContentBox'}>
                {this.state.PrizeTableList.map(
                  (item, index) =>
                    item.prize && (
                      <div className={'PrizeTableContent'} key={index}>
                        <div
                          className={'PrizeTableContentInfo'}
                          style={{ width: 150 }}
                        >
                          {this.matchNameReg(item.match_name)}
                        </div>
                        <div
                          className={'PrizeTableContentInfo'}
                          style={{ width: 400 }}
                        >
                          {item.item_name}
                        </div>
                        <div
                          className={'PrizeTableContentInfo'}
                          style={{ width: 150 }}
                        >
                          {item.prize ? item.prize : '/'}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.Ren === 5 && (
          <div style={{ fontSize: '36px', color: 'red', margin: '200px' }}>
            相关奖项已领取。
          </div>
        )}
        {this.state.PopUp === '提示弹窗' && (
          <PopUp
            ChildBackClick={this.ChildBackTip.bind(this)}
            type={'提示'}
            message={this.state.MessageTip}
            OutButton={'Yes'}
          />
        )}
      </div>
    )
  }
}

export default Prize
