import React, { Component } from 'react'
import './exam.css'
import ThreeLevelLinkage from '../round/ThreeLevelLinkage'
import Triangle from '../round/Triangle'
//import ExamStudentArr from "./ExamStudent";
//import ExamTeacherArr from "./ExamTeacher";
import PopUp from '../round/PopUp'
import { get, post } from '../../ajax/tools'
//import {get,} from "../../ajax/tools";

class ExamRoomInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ThreeLevelLinkage: 'none',
      Year: '', //选择申请临时考场年
      Month: '', //选择申请临时考场月
      Day: '', //选择申请临时考场日
      SubjectIdentity: '教员', //申请临时考场时科目选择的身份
      ExamMorningPeopleNum: '', //申请临时考场时上午的人数
      ExamAfternoonPeopleNum: '', //申请临时考场时下午的人数
      MorningToggleOn: true,
      MorningDisplay: 'none',
      AfternoonToggleOn: true,
      AfternoonDisplay: 'none',
      MorningTimeArr: [
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
      ], //上午的时间选择
      MorningStartTime: '', //申请临时考场时上午的开始时间
      MorningEndTime: '', //申请临时考场时上午的结束时间
      AfternoonTimeArr: [
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
      ], //下午的时间选择
      AfternoonStartTime: '', //申请临时考场时下午的开始时间
      AfternoonEndTime: '', //申请临时考场时下午的结束时间
      MorningStartTimeToggleOn: true,
      MorningStartTimeDisplay: 'none',
      MorningEndTimeToggleOn: true,
      MorningEndTimeDisplay: 'none',
      AfternoonStartTimeToggleOn: true,
      AfternoonStartTimeDisplay: 'none',
      AfternoonEndTimeToggleOn: true,
      AfternoonEndTimeDisplay: 'none',

      ExamTeacherArr: [], //教练员考阶的科目等级
      ExamStudentArr: [], //学员考阶的科目等级
      ExamTeacherSelectedArr: [], //教练员考阶的科目选择
      ExamStudentSelectedArr: [], //学员考阶的科目选择
      ExamName: '', //申请临时考场时联系人名字
      ExamDeviceNum: '', //申请临时考场时的设备数量
      ExamPhoneNum: '', //申请临时考场时的联系方式
      ExamAddress: '', //申请临时考场时的考试地点

      ExamTrain: '', //申请临时考场时单位的机构
      ExamTrainId: '', //申请临时考场时单位的机构
      ExamTrainArr: [], //申请临时考场时单位的机构
      ExamTrainToggleOn: true,
      ExamTrainDisplay: 'none',
    }
  }
  componentDidMount = function () {
    get({ url: 'exam/get_exam_kind' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          ExamTeacherArr: res.coach,
          ExamStudentArr: res.stu,
        })
      } else {
        this.setState({
          PopUp: '接口未知错误请求提示框',
          MessageTip: '未知错误，请联系我们！',
        })
      }
    })
    get({ url: 'agency/train_inst_query_all' }).then((res) => {
      if (res.errno === '200') {
        this.setState({
          ExamTrainArr: res.data,
        })
      } else {
        this.setState({
          ExamTrainArr: [],
        })
      }
    })
    const ExamTeacherArr = []
    const ExamStudentArr = []
    for (let i = 0; i < this.state.ExamTeacherArr.length; i++) {
      const ExamTeacherList = this.state.ExamTeacherArr[i]
      ExamTeacherList.Checkbox = false
      ExamTeacherArr.push(ExamTeacherList)
    }
    for (let i = 0; i < this.state.ExamStudentArr.length; i++) {
      const ExamStudentList = this.state.ExamStudentArr[i]
      ExamStudentList.Checkbox = false
      ExamStudentArr.push(ExamStudentList)
    }
    this.setState({
      ExamTeacherArr: ExamTeacherArr,
      ExamStudentArr: ExamStudentArr,
    })
    document.onclick = this.AllClick.bind(this) //下拉菜单监听隐藏
  }

  AllClick = () => {
    this.setState({
      ThreeLevelLinkage: 'none',
      MorningStartTimeToggleOn: true,
      MorningStartTimeDisplay: 'none',
      MorningEndTimeToggleOn: true,
      MorningEndTimeDisplay: 'none',
      AfternoonStartTimeToggleOn: true,
      AfternoonStartTimeDisplay: 'none',
      AfternoonEndTimeToggleOn: true,
      AfternoonEndTimeDisplay: 'none',
      ExamTrainToggleOn: true,
      ExamTrainDisplay: 'none',
    })
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  //三级联动的逻辑处理
  ChildLinkClick = (event) => {
    if (event.Type === '出生日期') {
      if (event.Title === '年') {
        this.setState({
          Year: event.ValueY,
          Month: event.ValueM,
          Day: event.ValueD,
        })
      } else if (event.Title === '月') {
        this.setState({
          Year: event.ValueY,
          Month: event.ValueM,
          Day: event.ValueD,
        })
      } else if (event.Title === '日') {
        this.setState({
          Year: event.ValueY,
          Month: event.ValueM,
          Day: event.ValueD,
        })
      }
    }
  }

  //点击选择上午的考试时间
  MorningStart() {
    this.setState((prevState) => ({
      MorningToggleOn: !prevState.MorningToggleOn,
      MorningDisplay: prevState.MorningToggleOn ? 'block' : 'none',
      ExamMorningPeopleNum: '', //申请临时考场时上午的人数
      MorningStartTime: '', //申请临时考场时上午的开始时间
      MorningEndTime: '', //申请临时考场时上午的结束时间
    }))
  }
  //选择下午的考试时间
  AfternoonStart() {
    this.setState((prevState) => ({
      AfternoonToggleOn: !prevState.AfternoonToggleOn,
      AfternoonDisplay: prevState.AfternoonToggleOn ? 'block' : 'none',
      ExamAfternoonPeopleNum: '', //申请临时考场时下午的人数
      AfternoonStartTime: '', //申请临时考场时下午的开始时间
      AfternoonEndTime: '', //申请临时考场时下午的结束时间
    }))
  }

  //输入上午的人数
  ExamMorningPeopleNum(event) {
    event.target.value = event.target.value
      .replace(/[^\d]/g, '')
      .replace(/^0/g, '')
    this.setState({
      ExamMorningPeopleNum: event.target.value,
    })
  }
  //输入下午的人数
  ExamAfternoonPeopleNum(event) {
    event.target.value = event.target.value
      .replace(/[^\d]/g, '')
      .replace(/^0/g, '')
    this.setState({
      ExamAfternoonPeopleNum: event.target.value,
    })
  }

  //点击上午的开始时间
  MorningStartBtn(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      MorningStartTimeToggleOn: !prevState.MorningStartTimeToggleOn,
      MorningStartTimeDisplay: prevState.MorningStartTimeToggleOn
        ? 'block'
        : 'none',
      MorningEndTimeToggleOn: true,
      MorningEndTimeDisplay: 'none',
      AfternoonStartTimeToggleOn: true,
      AfternoonStartTimeDisplay: 'none',
      AfternoonEndTimeToggleOn: true,
      AfternoonEndTimeDisplay: 'none',
      ExamTrainToggleOn: true,
      ExamTrainDisplay: 'none',
    }))
  }
  //选择上午的开始时间
  MorningTimeStart(item, index) {
    let MorningEndTime = this.state.MorningEndTime
    if (MorningEndTime !== '') {
      if (MorningEndTime > item) {
        this.setState({
          MorningStartTime: item,
        })
      } else {
        this.setState({
          PopUp: '必填项未的提示框',
          MessageTip: '时间选择错误！',
        })
      }
    } else {
      this.setState({
        MorningStartTime: item,
      })
    }
  }
  //点击上午结束的时间
  MorningEndBtn(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      MorningEndTimeToggleOn: !prevState.MorningEndTimeToggleOn,
      MorningEndTimeDisplay: prevState.MorningEndTimeToggleOn
        ? 'block'
        : 'none',
      MorningStartTimeToggleOn: true,
      MorningStartTimeDisplay: 'none',
      AfternoonStartTimeToggleOn: true,
      AfternoonStartTimeDisplay: 'none',
      AfternoonEndTimeToggleOn: true,
      AfternoonEndTimeDisplay: 'none',
      ExamTrainToggleOn: true,
      ExamTrainDisplay: 'none',
    }))
  }
  //选择上午结束的时间
  MorningTimeEnd(item, index) {
    let MorningStartTime = this.state.MorningStartTime
    if (MorningStartTime !== '') {
      if (item > MorningStartTime) {
        this.setState({
          MorningEndTime: item,
        })
      } else {
        this.setState({
          PopUp: '必填项未的提示框',
          MessageTip: '时间选择错误！',
        })
      }
    } else {
      this.setState({
        PopUp: '必填项未的提示框',
        MessageTip: '请先选择开始时间！',
      })
    }
  }

  //点击下午的开始时间
  AfternoonStartBtn(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      AfternoonStartTimeToggleOn: !prevState.AfternoonStartTimeToggleOn,
      AfternoonStartTimeDisplay: prevState.AfternoonStartTimeToggleOn
        ? 'block'
        : 'none',
      MorningStartTimeToggleOn: true,
      MorningStartTimeDisplay: 'none',
      MorningEndTimeToggleOn: true,
      MorningEndTimeDisplay: 'none',
      AfternoonEndTimeToggleOn: true,
      AfternoonEndTimeDisplay: 'none',
      ExamTrainToggleOn: true,
      ExamTrainDisplay: 'none',
    }))
  }
  //选择下午的开始时间
  AfternoonTimeStart(item, index) {
    let AfternoonEndTime = this.state.AfternoonEndTime
    if (AfternoonEndTime !== '') {
      if (AfternoonEndTime > item) {
        this.setState({
          AfternoonStartTime: item,
        })
      } else {
        this.setState({
          PopUp: '必填项未的提示框',
          MessageTip: '时间选择错误！',
        })
      }
    } else {
      this.setState({
        AfternoonStartTime: item,
      })
    }
  }

  //点击下午的开始时间
  AfternoonEndBtn(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      AfternoonEndTimeToggleOn: !prevState.AfternoonEndTimeToggleOn,
      AfternoonEndTimeDisplay: prevState.AfternoonEndTimeToggleOn
        ? 'block'
        : 'none',
      MorningStartTimeToggleOn: true,
      MorningStartTimeDisplay: 'none',
      MorningEndTimeToggleOn: true,
      MorningEndTimeDisplay: 'none',
      AfternoonStartTimeToggleOn: true,
      AfternoonStartTimeDisplay: 'none',
      ExamTrainToggleOn: true,
      ExamTrainDisplay: 'none',
    }))
  }
  //选择下午的开始时间
  AfternoonTimeEnd(item, index) {
    let AfternoonStartTime = this.state.AfternoonStartTime
    if (AfternoonStartTime !== '') {
      if (item > AfternoonStartTime) {
        this.setState({
          AfternoonEndTime: item,
        })
      } else {
        this.setState({
          PopUp: '必填项未的提示框',
          MessageTip: '时间选择错误！',
        })
      }
    } else {
      this.setState({
        PopUp: '必填项未的提示框',
        MessageTip: '请先选择开始时间！',
      })
    }
  }

  //点击选择教员身份
  SubjectTeacherBtn() {
    this.setState({
      SubjectIdentity: '教员',
    })
  }
  //点击选择学员身份
  SubjectStudentBtn() {
    this.setState({
      SubjectIdentity: '学员',
    })
  }

  //点击选择教员的单个多选框
  ExamTeacher = (item, index) => {
    const ExamTeacherInfoList = this.state.ExamTeacherArr
    ExamTeacherInfoList[index]['Checkbox'] = item.Checkbox !== true
    const ExamTeacherSelectedArr = [...this.state.ExamTeacherSelectedArr]
    if (item.Checkbox === true) {
      ExamTeacherSelectedArr.push(item.id)
      this.setState({
        ExamTeacherSelectedArr: ExamTeacherSelectedArr,
      })
    } else if (item.Checkbox === false) {
      for (let i = 0; i < ExamTeacherSelectedArr.length; i++) {
        if (ExamTeacherSelectedArr[i] === item.id) {
          ExamTeacherSelectedArr.splice(i, 1)
        }
      }
      this.setState({
        ExamTeacherSelectedArr: ExamTeacherSelectedArr,
      })
    }
  }

  //点击选择学员的单个多选框
  ExamStudent = (item, index) => {
    const ExamStudentInfoList = this.state.ExamStudentArr
    ExamStudentInfoList[index]['Checkbox'] = item.Checkbox !== true
    const ExamStudentSelectedArr = [...this.state.ExamStudentSelectedArr]
    if (item.Checkbox === true) {
      ExamStudentSelectedArr.push(item.id)
      this.setState({
        ExamStudentSelectedArr: ExamStudentSelectedArr,
      })
    } else if (item.Checkbox === false) {
      for (let i = 0; i < ExamStudentSelectedArr.length; i++) {
        if (ExamStudentSelectedArr[i] === item.id) {
          ExamStudentSelectedArr.splice(i, 1)
        }
      }
      this.setState({
        ExamStudentSelectedArr: ExamStudentSelectedArr,
      })
    }
  }

  //申请临时考场时的设备数量
  ExamDeviceNum(event) {
    event.target.value = event.target.value
      .replace(/[^\d]/g, '')
      .replace(/^0/g, '')
    this.setState({
      ExamDeviceNum: event.target.value,
    })
  }
  //申请临时考场时的联系方式
  ExamPhoneNum(event) {
    event.target.value = event.target.value
      .replace(/[^\d]/g, '')
      .replace(/^0/g, '')
    this.setState({
      ExamPhoneNum: event.target.value,
    })
  }
  ExamName(event) {
    this.setState({
      ExamName: event.target.value,
    })
  }
  //申请临时考场时的考试地点
  ExamAddress(event) {
    this.setState({
      ExamAddress: event.target.value,
    })
  }

  //申请临时考场时的考试单位
  ExamTrainDown(e) {
    e.nativeEvent.stopImmediatePropagation()
    this.setState((prevState) => ({
      ExamTrainToggleOn: !prevState.ExamTrainToggleOn,
      ExamTrainDisplay: prevState.ExamTrainToggleOn ? 'block' : 'none',
      MorningStartTimeToggleOn: true,
      MorningStartTimeDisplay: 'none',
      MorningEndTimeToggleOn: true,
      MorningEndTimeDisplay: 'none',
      AfternoonStartTimeToggleOn: true,
      AfternoonStartTimeDisplay: 'none',
      AfternoonEndTimeToggleOn: true,
      AfternoonEndTimeDisplay: 'none',
    }))
  }
  //申选择考试单位
  ExamTrainClick(item, index) {
    this.setState({
      ExamTrain:
        item.province === item.city
          ? item.city + item.county + item.name
          : item.province + item.city + item.county + item.name,
      ExamTrainId: item.id,
    })
  }

  //本页面填写完毕点击下一页（提交）
  ExamRoomInfoBtn() {
    let Year = this.state.Year
    let Month = this.state.Month
    let Day = this.state.Day
    let ExamTeacherSelectedArr = this.state.ExamTeacherSelectedArr
    let ExamStudentSelectedArr = this.state.ExamStudentSelectedArr
    let ExamSelectedArr = ExamTeacherSelectedArr.concat(ExamStudentSelectedArr)
    let ExamDeviceNum = this.state.ExamDeviceNum
    let ExamPhoneNum = this.state.ExamPhoneNum
    let ExamAddress = this.state.ExamAddress
    let ExamTrainId = this.state.ExamTrainId
    let ExamName = this.state.ExamName
    if (
      Year !== '' &&
      Month !== '' &&
      Day !== '' &&
      ExamDeviceNum !== '' &&
      ExamName !== '' &&
      ExamPhoneNum !== '' &&
      ExamAddress !== '' &&
      ExamTrainId !== '' &&
      ExamSelectedArr.length !== 0 &&
      (this.state.MorningDisplay === 'block' ||
        this.state.AfternoonDisplay === 'block') &&
      (this.state.ExamMorningPeopleNum !== '' ||
        this.state.ExamAfternoonPeopleNum !== '') &&
      (this.state.MorningStartTime !== '' ||
        this.state.AfternoonStartTime !== '') &&
      (this.state.MorningEndTime !== '' || this.state.AfternoonEndTime !== '')
    ) {
      post({
        url: 'exam/exam_room_apply',
        data: {
          time: Year + '-' + Month + '-' + Day,
          forenoon: this.state.MorningDisplay === 'block' ? 0 : '',
          morning_start:
            this.state.MorningDisplay === 'block'
              ? Year +
                '-' +
                Month +
                '-' +
                Day +
                ' ' +
                this.state.MorningStartTime +
                ':00'
              : '',
          morning_end:
            this.state.MorningDisplay === 'block'
              ? Year +
                '-' +
                Month +
                '-' +
                Day +
                ' ' +
                this.state.MorningEndTime +
                ':00'
              : '',
          morning_per_num:
            this.state.MorningDisplay === 'block'
              ? this.state.ExamMorningPeopleNum
              : '',
          afternoon: this.state.AfternoonDisplay === 'block' ? 1 : '',
          afternoon_start:
            this.state.AfternoonDisplay === 'block'
              ? Year +
                '-' +
                Month +
                '-' +
                Day +
                ' ' +
                this.state.AfternoonStartTime +
                ':00'
              : '',
          afternoon_end:
            this.state.AfternoonDisplay === 'block'
              ? Year +
                '-' +
                Month +
                '-' +
                Day +
                ' ' +
                this.state.AfternoonEndTime +
                ':00'
              : '',
          afternoon_per_num:
            this.state.AfternoonDisplay === 'block'
              ? this.state.ExamAfternoonPeopleNum
              : '',
          address: ExamAddress,
          kinds: ExamSelectedArr,
          equipment_num: ExamDeviceNum,
          contact: ExamName,
          phone: ExamPhoneNum,
          ti_id: ExamTrainId,
        },
      }).then((res) => {
        if (res.errno === '200') {
          this.props.ExamRoomInfoBack('ExamRoomSubmit')
        } else {
        }
      })
    } else {
      this.setState({
        PopUp: '必填项未的提示框',
        MessageTip: '有必填项未填写完全！',
      })
    }
  }

  //弹窗点击的逻辑处理
  ChildBackClick = (event) => {
    if (event === 'CrossOut') {
      this.setState({
        PopUp: '',
      })
    } else if (event === 'Confirm') {
      this.setState({
        PopUp: '',
      })
    }
  }

  render() {
    return (
      <div className={'scrollBar'} style={{ height: window.innerHeight - 246 }}>
        <div className={'ExamMainBoxes scrollBar'}>
          <div className={'ExamMainBoxesTit'}>填写申请信息</div>
          <div className={'ExamTimeBox clearfix'}>
            <div className={'ExamItemTitName'}>*考试时间</div>
            <div className={'ExamTimeActBox'}>
              <ThreeLevelLinkage
                ChildLinkClick={this.ChildLinkClick.bind(this)}
                type={'出生日期'}
                TwoType={'未来时间'}
                Width={336}
                Height={24}
                ThreeLevelLinkage={this.state.ThreeLevelLinkage}
              />
            </div>
          </div>
          <div className={'ExamTimeSiteBox'} style={{ marginBottom: 20 }}>
            <div className={'ExamTimeListBox clearfix'}>
              <div
                className={'ExamTimeChBox'}
                onClick={this.MorningStart.bind(this)}
              >
                <div
                  className={'ExamTimeSelectedBox'}
                  style={{ display: this.state.MorningDisplay }}
                >
                  {' '}
                </div>
              </div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 40,
                  color: this.state.MorningDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                上午:
              </div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 10,
                  color: this.state.MorningDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                *可接纳人数
              </div>
              <div
                className={'ExamPeopleNumBox'}
                style={{
                  marginRight: 46,
                  color: this.state.MorningDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                <input
                  className={'ExamPeopleNum'}
                  type={'text'}
                  name={'ExamPeopleNum1'}
                  value={this.state.ExamMorningPeopleNum}
                  onChange={this.ExamMorningPeopleNum.bind(this)}
                  placeholder={''}
                  disabled={
                    this.state.MorningDisplay === 'none' ? 'disabled' : ''
                  }
                  autoComplete={'off'}
                />
              </div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 6,
                  color: this.state.MorningDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                开始
              </div>
              <div
                className={'StartTimeBox'}
                style={{
                  marginRight: 6,
                  backgroundColor:
                    this.state.MorningDisplay === 'none'
                      ? '#D5D5D5'
                      : '#6EC2FF',
                }}
                onClick={
                  this.state.MorningDisplay === 'none'
                    ? null
                    : this.MorningStartBtn.bind(this)
                }
              >
                {this.state.MorningStartTime}
                <Triangle
                  Direction={'down'}
                  Color={'#FFFFFF'}
                  Width={'6.5px'}
                  Height={'10px'}
                  Top={'8px'}
                  Right={'10px'}
                />
                <div
                  className={'TimeActiveBox'}
                  style={{ display: this.state.MorningStartTimeDisplay }}
                >
                  {this.state.MorningTimeArr.map((item, index) => (
                    <div
                      className={'TimeActiveList'}
                      key={index}
                      onClick={() => this.MorningTimeStart(item, index)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className={'PoZheHao'}>　</div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 6,
                  color: this.state.MorningDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                结束
              </div>
              <div
                className={'StartTimeBox'}
                style={{
                  backgroundColor:
                    this.state.MorningDisplay === 'none'
                      ? '#D5D5D5'
                      : '#6EC2FF',
                }}
                onClick={
                  this.state.MorningDisplay === 'none'
                    ? null
                    : this.MorningEndBtn.bind(this)
                }
              >
                {this.state.MorningEndTime}
                <Triangle
                  Direction={'down'}
                  Color={'#FFFFFF'}
                  Width={'6.5px'}
                  Height={'10px'}
                  Top={'8px'}
                  Right={'10px'}
                />
                <div
                  className={'TimeActiveBox'}
                  style={{ display: this.state.MorningEndTimeDisplay }}
                >
                  {this.state.MorningTimeArr.map((item, index) => (
                    <div
                      className={'TimeActiveList'}
                      key={index}
                      onClick={() => this.MorningTimeEnd(item, index)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={'ExamTimeListBox clearfix'}>
              <div
                className={'ExamTimeChBox'}
                onClick={this.AfternoonStart.bind(this)}
              >
                <div
                  className={'ExamTimeSelectedBox'}
                  style={{ display: this.state.AfternoonDisplay }}
                >
                  {' '}
                </div>
              </div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 40,
                  color:
                    this.state.AfternoonDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                下午:
              </div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 10,
                  color:
                    this.state.AfternoonDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                *可接纳人数
              </div>
              <div
                className={'ExamPeopleNumBox'}
                style={{
                  marginRight: 46,
                  color:
                    this.state.AfternoonDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                <input
                  className={'ExamPeopleNum'}
                  type={'text'}
                  name={'ExamPeopleNum2'}
                  value={this.state.ExamAfternoonPeopleNum}
                  onChange={this.ExamAfternoonPeopleNum.bind(this)}
                  placeholder={''}
                  disabled={
                    this.state.AfternoonDisplay === 'none' ? 'disabled' : ''
                  }
                  autoComplete={'off'}
                />
              </div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 6,
                  color:
                    this.state.AfternoonDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                开始
              </div>
              <div
                className={'StartTimeBox'}
                style={{
                  marginRight: 6,
                  backgroundColor:
                    this.state.AfternoonDisplay === 'none'
                      ? '#D5D5D5'
                      : '#6EC2FF',
                }}
                onClick={
                  this.state.AfternoonDisplay === 'none'
                    ? null
                    : this.AfternoonStartBtn.bind(this)
                }
              >
                {this.state.AfternoonStartTime}
                <Triangle
                  Direction={'down'}
                  Color={'#FFFFFF'}
                  Width={'6.5px'}
                  Height={'10px'}
                  Top={'8px'}
                  Right={'10px'}
                />
                <div
                  className={'TimeActiveBox'}
                  style={{ display: this.state.AfternoonStartTimeDisplay }}
                >
                  {this.state.AfternoonTimeArr.map((item, index) => (
                    <div
                      className={'TimeActiveList'}
                      key={index}
                      onClick={() => this.AfternoonTimeStart(item, index)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
              <div className={'PoZheHao'}>　</div>
              <div
                className={'ExamTimeListWord'}
                style={{
                  marginRight: 6,
                  color:
                    this.state.AfternoonDisplay === 'none' ? '#D5D5D5' : '',
                }}
              >
                结束
              </div>
              <div
                className={'StartTimeBox'}
                style={{
                  backgroundColor:
                    this.state.AfternoonDisplay === 'none'
                      ? '#D5D5D5'
                      : '#6EC2FF',
                }}
                onClick={
                  this.state.AfternoonDisplay === 'none'
                    ? null
                    : this.AfternoonEndBtn.bind(this)
                }
              >
                {this.state.AfternoonEndTime}
                <Triangle
                  Direction={'down'}
                  Color={'#FFFFFF'}
                  Width={'6.5px'}
                  Height={'10px'}
                  Top={'8px'}
                  Right={'10px'}
                />
                <div
                  className={'TimeActiveBox'}
                  style={{ display: this.state.AfternoonEndTimeDisplay }}
                >
                  {this.state.AfternoonTimeArr.map((item, index) => (
                    <div
                      className={'TimeActiveList'}
                      key={index}
                      onClick={() => this.AfternoonTimeEnd(item, index)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={'ExamTimeBox clearfix'}>
            <div className={'ExamItemTitName ExamSubjectTit'}>*测试科目</div>
            <div className={'ExamSubjectIdBtn clearfix'}>
              <div
                className={'SubjectIdBtnBox'}
                style={
                  this.state.SubjectIdentity === '教员'
                    ? { backgroundColor: '#6EC2FF' }
                    : { borderBottom: '1px solid #000000' }
                }
                onClick={this.SubjectTeacherBtn.bind(this)}
              >
                教员
              </div>
              <div
                className={'SubjectIdBtnBox'}
                style={
                  this.state.SubjectIdentity === '学员'
                    ? { backgroundColor: '#6EC2FF' }
                    : { borderBottom: '1px solid #000000' }
                }
                onClick={this.SubjectStudentBtn.bind(this)}
              >
                学员
              </div>
            </div>
          </div>
          <div
            className={'ExamSubjectActiveBox clearfix'}
            style={{ marginBottom: 20 }}
          >
            {this.state.SubjectIdentity === '教员' && (
              <div className={'SubjectListBoxes'}>
                {this.state.ExamTeacherArr.map((item, index) => (
                  <div className={'SubjectListBox'} key={index}>
                    <div
                      className={'ExamTimeChBox'}
                      style={{ margin: '3px 18px' }}
                      onClick={() => this.ExamTeacher(item, index)}
                    >
                      <div
                        className={'ExamTimeSelectedBox'}
                        style={{
                          display: item.Checkbox === true ? 'block' : 'none',
                        }}
                      >
                        {' '}
                      </div>
                    </div>
                    <div className={'SubjectListWord'}>{item.name}</div>
                  </div>
                ))}
              </div>
            )}
            {this.state.SubjectIdentity === '学员' && (
              <div className={'SubjectListBoxes'}>
                {this.state.ExamStudentArr.map((item, index) => (
                  <div className={'SubjectListBox'} key={index}>
                    <div
                      className={'ExamTimeChBox'}
                      style={{ margin: '3px 18px' }}
                      onClick={() => this.ExamStudent(item, index)}
                    >
                      <div
                        className={'ExamTimeSelectedBox'}
                        style={{
                          display: item.Checkbox === true ? 'block' : 'none',
                        }}
                      >
                        {' '}
                      </div>
                    </div>
                    <div className={'SubjectListWord'}>{item.name}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={'ExamTimeBox clearfix'} style={{ marginBottom: 20 }}>
            <div style={{ float: 'left', width: 380 }}>
              <div className={'ExamItemTitName'}>*联系人姓名</div>
              <div className={'ExamRoomInputBox'}>
                <input
                  className={'ExamRoomInput'}
                  type={'text'}
                  name={'ExamName'}
                  value={this.state.ExamName}
                  onChange={this.ExamName.bind(this)}
                  placeholder={''}
                  autoComplete={'off'}
                />
              </div>
            </div>
            <div style={{ float: 'right', width: 380 }}>
              <div className={'ExamItemTitName'}>*联系人电话</div>
              <div className={'ExamRoomInputBox'}>
                <input
                  className={'ExamRoomInput'}
                  type={'text'}
                  name={'ExamPhoneNum'}
                  value={this.state.ExamPhoneNum}
                  onChange={this.ExamPhoneNum.bind(this)}
                  placeholder={''}
                  autoComplete={'off'}
                />
              </div>
            </div>
          </div>
          <div className={'ExamTimeBox clearfix'} style={{ marginBottom: 20 }}>
            <div style={{ float: 'left', width: 380 }}>
              <div className={'ExamItemTitName'}>*设备数量</div>
              <div className={'ExamRoomInputBox'}>
                <input
                  className={'ExamRoomInput'}
                  type={'text'}
                  name={'ExamDeviceNum'}
                  value={this.state.ExamDeviceNum}
                  onChange={this.ExamDeviceNum.bind(this)}
                  placeholder={''}
                  autoComplete={'off'}
                />
              </div>
            </div>
          </div>
          <div className={'ExamTimeBox clearfix'} style={{ marginBottom: 20 }}>
            <div className={'ExamItemTitName'}>*考试单位</div>
            <div
              className={'ExamRoomInputBox'}
              style={{ float: 'right', width: 670, position: 'relative' }}
              onClick={this.ExamTrainDown.bind(this)}
            >
              <div className={'ExamTrainTitle'}>{this.state.ExamTrain}</div>
              <div className={'ExamTrainIcoBox'}>
                <Triangle
                  Direction={'down'}
                  Color={'#FFFFFF'}
                  Width={'6.5px'}
                  Height={'10px'}
                  Top={'10px'}
                  Right={'7px'}
                />
              </div>
              <div
                className={'ExamTrainDownBox scrollBar'}
                style={{ display: this.state.ExamTrainDisplay }}
              >
                {this.state.ExamTrainArr.map((item, index) => (
                  <div
                    className={'ExamTrainList'}
                    key={index}
                    onClick={() => this.ExamTrainClick(item, index)}
                  >
                    {item.province === item.city
                      ? item.city + item.county + item.name
                      : item.province + item.city + item.county + item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={'ExamTimeBox clearfix'}>
            <div className={'ExamItemTitName'}>*考试地点</div>
            <div
              className={'ExamRoomInputBox'}
              style={{ float: 'right', width: 670 }}
            >
              <input
                className={'ExamRoomInput'}
                style={{ width: 658 }}
                type={'text'}
                name={'ExamAddress'}
                value={this.state.ExamAddress}
                onChange={this.ExamAddress.bind(this)}
                placeholder={''}
                autoComplete={'off'}
              />
            </div>
          </div>
          <div className={'PageAnnotate'}>（* 为必填项）</div>
          <button
            className={'ExamRoomInfoBtn'}
            style={{ marginBottom: 30 }}
            onClick={this.ExamRoomInfoBtn.bind(this)}
          >
            下一步
          </button>
        </div>
        {this.state.PopUp === '必填项未的提示框' && (
          <PopUp
            ChildBackClick={this.ChildBackClick.bind(this)}
            type={'提示'}
            message={this.state.MessageTip}
            OutButton={'Yes'}
          />
        )}
        {this.state.PopUp === '接口未知错误请求提示框' && (
          <PopUp
            ChildBackClick={this.ChildBackClick.bind(this)}
            type={'提示'}
            message={this.state.MessageTip}
            OutButton={'Yes'}
          />
        )}
      </div>
    )
  }
}

export default ExamRoomInfo
